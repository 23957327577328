#Agent {
  margin-top: 65px;
  margin-bottom: 6rem;

  textarea {
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #013399;
    padding: 0rem 0rem 0rem 0.5rem;
    width: 100%;
  }
}

.background-grey {
  background-color: #fff;
}

.inputs-contact {
  display: flex;
  gap: 0.5rem;
  input,
  select {
    width: 100% !important;
    max-width: 100%;
    height: 40px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #013399;
    padding: 0rem 0rem 0rem 0.5rem;
  }
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 990px) {
  #Agent {
    margin-top: 50px;
  }
  .inputs-contact {
    display: grid;
    input {
      margin-bottom: 0.5rem;
    }
  }
}
