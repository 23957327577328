#market-properties {
  margin-bottom: 6rem;
  .selection-type {
    button {
      background: transparent;
      border: none;
      border-bottom: 2px solid transparent;
      color: black;
      opacity: 0.75;
    }
    button.active {
      opacity: 1;
      color: #003399;
      border-bottom: 2px solid #003399;
    }
    button:hover {
      color: #003399;
    }
  }
}
