.navbar {
  background: #003399;
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: margin-top 0.75s ease, width 0.5s ease;
  transform-origin: center;
  transform: translateX(-50%) scale(1);
  left: 50%;
  top: 0;
  height: 65px;
  .navbar-brand {
    display: flex;
    align-items: center;
    .logo {
      object-fit: contain;
      width: 260px;
      height: auto;
    }
  }

  .nav-options {
    margin-left: auto;
    .item {
      color: #fff !important;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
    }
    .item:hover {
      color: #d7d7d7 !important;
      opacity: 0.75;
    }
    .active {
      color: #d7d7d7 !important;
    }
  }

  .button-menu {
    border: none;
    border-color: transparent;
    overflow: hidden;
    box-shadow: none;
    span {
      background-image: url('../../assets/icons/close.svg');
    }
    &:focus {
      box-shadow: none;
    }
  }
  .button-menu:focus {
    outline: none;
  }
  .collapsed {
    span {
      background-image: url('../../assets/icons/menu.svg');
    }
  }

  .nav-link {
    transition: transform 0.1s;
    &:hover {
      transform: scale(1.055);
    }
  }

  img {
    transition: transform 0.1s;
    &:hover {
      transform: scale(1.055);
    }
  }
}

.navbar.top-0 {
  margin-top: 4rem;
  width: 90%;
  overflow: visible;
  transition: margin-top 0.75s ease;
  background: transparent;
  .container {
    padding-left: 0;
  }
  img {
    width: 280px !important;
  }
  .nav-options .item {
    font-size: 17px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
  }
}

.mobile-navbar {
  display: none !important;
}

@media screen and (max-width: 990px) {
  .navbar-toggler-icon {
    width: 1.75em;
    height: 1.75em;
  }
  .navbar.top-0 .nav-options .item {
    text-shadow: none;
  }
  .navbar {
    padding: 0 !important;
    transition: none;
    transform-origin: unset;
    transform: none;
    left: 0%;
    width: 100% !important;
    margin-top: 0rem !important;
    border-radius: 0rem !important;
    height: 70px;
    .navbar-brand {
      .logo {
        width: 240px !important;
      }
    }
    .mobile-navbar {
      display: inherit !important;
      background-color: white;
      width: 100%;
      position: fixed;
      z-index: 999;
      left: 0;
      height: 100%;
      top: 70px;
      .flex-column {
        border: solid 1px #e3e3e3;
        padding-top: 1rem;
        min-width: 100%;
        .nav-link {
          padding: 1rem;
          margin-bottom: 0.5rem;
        }
        .nav-link.active {
          background-color: #f6f6f6;
        }
        .item {
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #003399 !important;
        }
        .active {
          color: #003399 !important;
        }
      }
    }
  }
  .whatsapp {
    right: 1rem !important;
    bottom: 1rem !important;
  }

  .navbar.top-0 {
    .container {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.options-div {
  width: max-content;
  position: fixed;
  bottom: 2rem;
  z-index: 999;
  right: 2rem;
  @media screen and (max-width: 990px) {
    right: 1rem;
  }
  .options-div-item {
    margin-bottom: 0.5rem;
    color: white;
    padding: 0.5rem 1rem;
    background: #013399;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    font-size: 12px;
    img {
      width: 18px;
      height: auto;
      margin-right: 0.5rem;
    }
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.1);
    }
    a {
      align-items: center;
      color: white !important;
      text-decoration: none;
    }
  }
}
