.card {
  border-radius: 3%;
  -webkit-border-radius: 3%;
  -moz-border-radius: 3%;
  -ms-border-radius: 3%;
  -o-border-radius: 3%;
  margin-right: 1.5rem;
}

.card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.card-body {
  display: flex;
  flex-direction: column;
  font-size: 12;
  padding-top: 0;
}

.card-header {
  display: flex;
  flex-direction: row;
  gap: 15px;
  border: 0;
}

.stars {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.card-text {
  .show-more {
    color: #1890ff !important;
    text-decoration: underline;
  }
}

.row-rating {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    gap: 25px;
  }
}
