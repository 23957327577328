.pagination {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .item {
    cursor: pointer;
    border: 1px solid #003399;
    margin: 0rem 0.5rem;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      text-align: center;
      color: #003399;
    }
  }
  .active {
    border: 1px solid #003399;
    background-color: #003399;
    span {
      color: #fff;
    }
  }
  .arrow {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .arrow-disabled {
    pointer-events: none;
    cursor: default;
  }
}
