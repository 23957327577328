.footer-subscribe {
  padding: 0rem;
  background-repeat: no-repeat;
  background-size: 100% auto;
  .footer-subscribe-container {
    background: rgba(0, 0, 0, 0.5);
    padding: 2rem 0rem;
    text-align: center;
    h3 {
      color: white;
      width: max-content;
      margin: 0rem auto 2rem auto;
      max-width: 100%;
      width: 6000px;
    }
    input {
      height: 40px;
      width: 200px;
      border-radius: 3px;
      border: none;
      padding-left: 0.5rem;
      margin-bottom: 0.5rem;
      &:nth-child(1),
      &:nth-child(2) {
        margin-right: 10px;
      }
    }
    button {
      border: none;
      background-color: #003399;
      color: white;
      height: 40px;
      border-radius: 3px;
      width: 620px;
      margin: 0px;
    }
  }
}

.footer {
  background-color: #303441;
  display: grid;
  .footer-item {
    padding-top: 4rem;
    padding-bottom: 4rem;
    .img {
      margin-bottom: 1rem;
      text-align: center;
    }
    .details-item {
      margin-top: 0.5rem;
      display: inline-flex;
      width: 100%;
      label,
      a {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #d4d4d4;
        margin-left: 0.75rem;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        text-decoration: none;
      }
    }
    .title {
      color: white;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }

  .footer-item {
    .details-item {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .arrow-up {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0rem;
    bottom: 50%;
    width: max-content;
    .border-image {
      border: solid 1px #979797;
      padding: 0.4rem;
      cursor: pointer;
    }
  }
  .center-items {
    padding-top: 3rem;
    .title {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #f8f9fa;
      font-size: 14px;
      margin-top: 1rem;
    }
    .menu-item {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a5a5a5;
      display: list-item;
      list-style: inside;
      cursor: pointer;
    }
  }
}

.footer-credits {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  .reservation {
    label {
      font-size: 14px;
      color: #393939;
      margin-right: 5px;
    }
    a {
      font-size: 14px;
    }
  }
  .icons-redes {
    text-align: right;
    img {
      margin-right: 0.5rem;
    }
    .icon {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.desktop-footer {
  display: grid !important;
}

@media screen and (max-width: 990px) {
  .desktop-footer {
    display: none !important;
  }
  .footer {
    .separated-hr {
      border-top: 0.5px solid #727272;
      height: 0.5px;
      width: 80%;
      margin: auto;
    }
    .center-items {
      padding-top: 1rem;
      padding-bottom: 2rem;
      label {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f8f9fa;
        margin-bottom: 1rem;
      }
    }
    .footer-item {
      padding: 2rem;
      .img {
        text-align: left;
      }
      .details-item {
        label,
        a {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: #d4d4d4;
          margin-left: 1rem;
          margin-bottom: 0;
        }
      }
    }
  }

  .background-color-footer {
    background-color: #303441;
  }

  .footer-credits {
    .reservation {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      label {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #727272;
      }
    }
    .icons-redes {
      text-align: center;
      margin-top: 1rem;
      img {
        margin-right: 1rem;
      }
      .icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .footer-subscribe {
    background-size: auto 100%;
  }

  .footer-subscribe-container {
    padding: 2rem !important;
    input {
      width: calc(100% - 1rem) !important;
      margin-right: 0px !important;
    }
    button {
      margin-top: 0.5rem;
      width: calc(100% - 1rem) !important;
    }
  }
}
