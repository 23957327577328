$banner-height: 750px;

#banner-principal {
  /*background-attachment: fixed;
  background-image: url('../../../assets/images/principal-banner.jpeg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $banner-height;*/
  .banner-video {
    width: 100%;
    height: 100%;
  }
  .search-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: $banner-height;*/
    width: 100%;
    position: absolute;
    top: 30%;
    .search-container-box {
      background: #00000095;
      padding: 2rem;
      border-radius: 0px 0px 5px 5px;
      position: relative;
      .filter-box {
        padding: 0rem 2rem;
        display: flex;
        select {
          background: white;
        }
      }
    }
  }
  .banner-video-desktop {
    display: block;
  }
  .banner-video-mobile {
    display: none;
  }
  position: relative;
  .blur-black {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
  }
}

@media screen and (max-width: 990px) {
  #banner-principal {
    background-size: unset;
    background-position: unset;
    .banner-video {
      width: 100% !important;
      height: 100vh !important;
      object-fit: cover;
    }
    .search-container {
      padding: 0rem 1rem;
      div:nth-child(1) {
        width: 100%;
      }
      .search-container-box {
        width: calc(100% - 1rem);
        padding: 2rem 0.25rem;
        h3 {
          font-size: 1.5rem !important;
        }
        .filter-box {
          display: block;
          select,
          input {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0.5rem;
            height: 40px;
          }
          button {
            width: 100%;
          }
        }
      }
    }
    .banner-video-desktop {
      display: none;
    }
    .banner-video-mobile {
      display: block;
    }
  }
}
