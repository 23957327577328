.contact-form {
  margin-bottom: 2rem;
  .form-control {
    border-radius: 10px;
  }

  select {
    -webkit-appearance: auto;
    appearance: auto;
  }

  button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    color: #003399;
    border: 0.125rem solid #003399;
    width: 100%;
    background: white;
  }

  button:hover {
    opacity: 0.5;
  }

  textarea {
    resize: none;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }
}

#icons-info-contact {
  .box {
    margin-bottom: 4rem;
    .item {
      align-items: center;
      display: flex;
      margin-bottom: 1rem;
      span {
        margin-left: 5px;
        font-size: 18px;
        font-weight: 700;
        color: #4a4a4a;
      }
    }
    a {
      font-size: 16px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2a2a2a;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

#map-container{
  margin-top: 65px;
}

#offices {
  height: '290px'
}

@media screen and (max-width: 768px) {
  #map-container{
    margin-top: 50px;
  }
  #icons-info-contact {
    text-align: center;
    .box {
      .item {
        display: block !important;
      }
    }
  }
  #offices {
    height: '100%'
  }
}
