#banner-agent {
  .features {
    img {
      margin: 0rem !important;
      &:hover {
        opacity: 0.75;
      }
    }
    .item {
      text-align: center;
      text-decoration: none;
      color: #013399;
      display: grid;
      &:hover {
        color: #013399;
      }
      span {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
