.products-page-gallery {
  margin-top: 1rem;
}

.ant-tabs-tab-btn {
  color: #393939 !important;
}

.property-price {
  width: 100%;
  margin-top: 2rem;
  .price {
    padding: 0.5rem 1rem;
    border: solid 1px #003399;
    background-color: #ffffff;
    width: max-content;
    border-radius: 3px;
    span {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #393939;
    }
    .operation-type {
      margin-right: 4rem;
    }
  }
}

.property-description {
  margin-top: 3rem;
  h4 {
    margin-bottom: 2rem;
  }
  div {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #727272;
  }
}

hr {
  margin-top: 2rem;
}

.ficha-tecnica {
  margin-top: 3rem;
  .items {
    margin-top: 0.5rem;
    span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #727272;
    }
    .count-feature {
      float: right;
      margin-right: 0.5rem;
      margin-top: 6px;
    }
    hr {
      margin-top: 0.5rem;
    }
    .col-4 {
      margin-top: 1.5rem;
    }
  }
}

.information-contact {
  box-shadow: 4px 3px 19px -4px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 4px 3px 19px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 3px 19px -4px rgba(0, 0, 0, 0.75);
  .text {
    padding: 0 15px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #727272;
  }
  .icons-shared {
    margin-left: calc(15px - 0.5rem);
    label {
      font-size: 16px;
      font-weight: 500;
      color: #000000d9;
      margin-left: 10px;
    }
    .content-shared {
      display: flex;
      margin-top: 0.5rem;
      a {
        &:hover {
          opacity: 0.5;
        }
        border: none;
        background: transparent;
        color: #003399;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.separated {
  padding-bottom: 4rem;
  padding-top: 3rem;
  margin-bottom: 0;
  margin-top: 0;
}

.information-contact.separated {
  padding: 0.5rem;
  padding-bottom: 2rem;
}

.simiar-properties {
  a {
    text-decoration: none;
    color: #393939;
  }
}

.buttons-options {
  position: absolute;
  z-index: 2;
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
  button {
    background: #003399;
    color: white;
    border: none;
    padding: 0.25rem 2rem;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 5px;
    height: 15px;
    margin-bottom: 2px;
  }
}

#PropertyDetails {
  margin-top: 65px;

  .table-growth {
    div {
      display: flex;
      margin-bottom: 2rem;
      @media (max-width: 990px) {
        flex-direction: column;
      }
    }
    img {
      width: 30%;
      @media (max-width: 990px) {
        width: 100%;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      button {
        float: right;
        color: white;
        background: #013399;
      }
    }
    th,
    td {
      text-align: left;
      padding: 8px;
      border: 1px solid #ddd;
    }
  }
}

@media (min-width: 990px) {
  .col-md-35 {
    flex: 0 0 auto;
    width: 29.166666%;
  }
  .col-md-85 {
    flex: 0 0 auto;
    width: 70.8333333%;
  }
}

#PropertyDetails {
  .sold-or-rented {
    top: 40px;
    right: -60px;
    width: 260px;
    height: 60px;
    font-size: 22px;
  }
}

@media (max-width: 990px) {
  #PropertyDetails {
    margin-top: 50px;
    .sold-or-rented {
      top: 25px;
      right: -45px;
      width: 190px;
      height: 50px;
      font-size: 18px;
    }
  }
  .buttons-options {
    position: relative;
    justify-content: end;
    margin-bottom: 0.5rem;
    button {
      padding: 0.25rem;
    }
  }
}
