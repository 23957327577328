@import '~antd/dist/antd.css';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

.desktop {
  display: contents !important;
}

.mobile {
  display: none !important;
}

@media screen and (max-width: 990px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: contents !important;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: #013399;
}

.bold {
  font-weight: bold !important;
}

.item-image {
  position: relative;
  overflow: hidden;
}

.sold-or-rented {
  position: absolute;
  top: 25px;
  right: -45px;
  width: 170px;
  height: 35px;
  transform: rotate(45deg);
  background-color: #013399;
  color: #fff;
  font-size: 15px;
  text-align: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

@media (max-width: 990px) {
  .item-image {
    margin-right: 0px;
  }
  .row-with-max {
    max-width: 100%;
  }
}
