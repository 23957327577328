.agent {
  padding: 0rem 2rem 0rem 4rem;
  padding-right: 0rem;
  div {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}

.carousel-banners {
  margin-top: 8rem;
  div {
    img {
      width: 100%;
      height: auto;
      max-height: 500px;
      min-height: 500px;
      object-fit: cover;
    }
  }
}

.ant-carousel .slick-dots li,
.ant-carousel .slick-dots li.slick-active {
  width: 18px;
}

.ant-carousel .slick-dots li button {
  height: 18px;
  border-radius: 100%;
}

.modal-information {
  img {
    height: 100%;
    object-fit: cover;
  }
  .form-contact {
    padding-top: 0rem;
    margin-bottom: 0rem;
    .item {
      display: grid;
      margin-bottom: 0.75rem;
      label {
        color: #003399;
        font-size: 14px;
      }
      input,
      select,
      textarea {
        background: rgb(245, 245, 245);
        border: none;
        font-size: 14px;
        height: 40px;
        padding-left: 0.5rem;
        border-radius: 4px;
      }
      textarea {
        height: 120px;
      }
    }
    button {
      width: 100%;
      border: 2px solid #003399;
      background: transparent;
      color: #003399;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.modal-show-action-button {
  width: 100%;
  background: #003399;
  color: white;
  font-weight: 400;
  border: none;
  border-radius: 3px;
  padding: 0.5rem;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: white;
    opacity: 0.75;
  }
}

@media screen and (max-width: 990px) {
  .agent {
    padding: 0rem 1rem;
  }
  .welcome {
    margin-top: 1rem;
    padding: 0rem 1rem;
    img {
      margin-bottom: 1rem !important;
    }
  }
}
