#booking-component.container.no-modify-container {
  margin-top: 0;
  margin-bottom: 0;
}

#booking-component.container {
  margin-top: 100px;
  margin-bottom: 2rem;

  .booking-header {
    // Estilos para la cabecera del formulario
    text-align: center;
  }

  .booking-form {
    // Estilos generales del formulario
    .form-group {
      margin-bottom: 0.75rem;

      label {
        // Estilos para las etiquetas de los campos
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #727272;
      }

      input,
      .ant-select,
      .ant-picker {
        // Estilos para los campos de entrada
        width: 100%;
        border-radius: 5px;
        border: solid 1px #d4d4d4;
        padding-left: 0.75rem;
        height: 35px;
        font-size: 14px;
        .ant-picker-input {
          input {
            border: none;
            padding: 0rem;
          }
          padding-left: 0.75rem;
        }

        .ant-select-selector {
          display: flex;
          align-items: center;
          border: none;
          padding: 0rem;
          padding-left: 0.75rem;
        }
      }

      .ant-select,
      .ant-picker {
        padding: 0rem;
      }

      select {
        -webkit-appearance: auto;
        appearance: auto;
        height: 35px;
      }
    }

    .submit-button {
      // Estilos para el botón de envío
      width: 100%;
      margin-top: 0.75rem;
      padding: 5px 20px;
      background-color: #0056b3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken(#0056b3, 10%);
      }
    }
  }
}
