#properties {
  .col-12 {
    padding-right: 0rem;
  }
}

.filter-type-operation {
  .count-properties {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
}

.filter {
  text-align: right;
  img {
    margin-bottom: 4px;
  }
  span {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #727272;
  }
}

.box-content-filters {
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #fcfcfc;
  border: solid 1px #e3e3e3;
  .filter-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #393939;
  }
  .filters {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    .filter-item {
      border-radius: 5px;
      border: solid 1px #d4d4d4;
      background-color: #e3e3e3;
      margin-right: 0.5rem;
      padding: 0.5rem 1rem;
      position: relative;
      min-width: max-content;
      margin-bottom: 0.5rem;
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #727272;
      }
      img {
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 2px;
        padding: 0 0 0.2rem 0.2rem;
      }
    }
  }
  .filter-trash {
    .filter-trash-button {
      width: max-content;
      margin-left: auto;
      padding-left: 1rem;
      cursor: pointer;
      span {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        color: #727272;
        text-decoration: underline;
        text-decoration-color: #b4b4b4;
      }
    }
  }
  hr {
    border-top: solid 2px #e3e3e3;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }
  .type-label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #727272;
    margin-right: 1rem;
  }
  .box-items {
    margin-top: 1rem;
    display: flex;
    .box-item {
      border-radius: 4px;
      border: solid 1px #a5a5a5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      cursor: pointer;
      span {
        margin-top: 2px;
        font-family: Menlo;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.83;
        letter-spacing: normal;
        color: #a5a5a5;
        padding: 5px;
      }
    }
    .active {
      border: solid 1px #003399;
      background-color: #003399;
      span {
        color: white;
      }
    }
  }
  .property-type {
    display: flex;
  }
}

.filter-mobile {
  border: 1px solid rgb(0, 51, 153);
  padding: 0.3rem 1rem;
  border-radius: 3px;
  color: rgb(0, 51, 153);
  font-weight: 500;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  span {
    color: rgb(0, 51, 153);
  }
  img {
    margin-bottom: 0px;
  }
}

.desktop-filter {
  display: block !important;
  margin-top: 1rem;
}

.property-type.d-inline {
  a {
    font-size: 13px;
    color: #013399;
    text-decoration: underline;
  }
}

@media screen and (max-width: 990px) {
  .desktop-filter {
    display: none !important;
  }
  .mobile-filters {
    display: block !important;
  }
  #properties {
    margin-top: 70px;
  }
  .filter-type-operation {
    text-align: center;
  }
  .filter.mobile.text-center {
    display: flex !important;
    justify-content: center;
    width: 100%;
    margin-top: 0rem;
  }
  #properties .row {
    padding-left: 0;
    padding-right: 0;
  }
}

.filter-type-operation.order-by {
  text-align: right;
  select {
    border: 1px solid #003399;
    padding: 0.25rem 1rem;
    margin-right: 0.5rem;
    border-radius: 3px;
    color: #003399;
    font-weight: 500;
  }
}

.modal-filters {
  position: fixed;
  z-index: 999;
  background: white;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: scroll;
  img {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.mobile-filters {
  display: none;
}

.checkbox-button {
  display: flex;
  margin-top: 1rem;
  cursor: pointer;
  max-width: max-content;
  .checkbox-unmarked {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #a5a5a5;
    margin-right: 0.5rem;
  }
  .checkbox-marked {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: solid 1px #003399;
    background-color: #003399;
    margin-right: 0.5rem;
    position: relative;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 16px;
      height: 18px;
    }
  }
  label {
    cursor: pointer;
  }
}
