#PropertyItem {
  a {
    text-decoration: none;
  }
  label,
  span {
    color: black;
  }

  &.disabled {
    pointer-events: none !important;
    opacity: 0.5 !important;
  }

  &.item-is-for-booking {
    margin-bottom: 0rem !important;
  }

  &.item {
    margin-bottom: 1.5rem;
    .item-container {
      cursor: pointer;
      img {
        max-width: 100%;
        height: auto;
      }
      .item-details {
        border: 1px solid rgb(238, 238, 238);
        border-top: none;
        padding: 1rem 1rem 0.25rem 1rem;
        h6 {
          font-weight: 500;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          color: grey;
          margin-top: 0.5rem;
          font-size: 15px;
        }
        label {
          text-align: right;
          margin-right: auto;
          font-weight: 600;
          font-size: 18px;
        }
        a {
          background: white;
          border: none;
          color: #003399;
          padding: 0.25rem 1rem;
          border-radius: 3px;
          font-size: 12px;
          display: flex;
          align-items: center;
          border: 1px solid #003399;
          font-weight: 600;
          text-decoration: none;
        }
      }
      .item-details-features {
        background: rgb(238, 238, 238);
        padding: 0.75rem;
        display: flex;
        min-height: 48px;
        .item {
          margin: auto;
          span {
            font-size: 11px;
            margin-left: 5px;
            font-weight: 500;
          }
          img {
            height: 40px;
            width: auto;
          }
        }
      }
      transition: transform 0.2s; /* Animation */
      &:hover {
        transform: scale(1.035);
        opacity: 0.85;
        button {
          opacity: 0.85;
        }
      }
    }

    .item-details.is-for-booking {
      padding: 0.5rem 0.5rem 0.25rem 0.5rem;
      h6 {
        font-size: 14px !important;
        margin: 0;
      }
      p,
      label {
        margin: 0;
        font-size: 12px !important;
      }
    }
  }
}
