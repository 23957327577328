@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.loading-container {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  overflow: unset;
  .loading-content {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .image {
      animation: zoominoutsinglefeatured 1s infinite;
      height: 110px;
      width: auto;
      background-color: #003399;
      padding: 1px;
      border-radius: 100%;
    }
  }
}
