.form-contact {
  padding: 3rem 1rem 0rem 1rem;
  margin-bottom: 1rem;
  .form-group {
    input {
      border-radius: 5px;
      border: solid 1px #d4d4d4;
    }
    label {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #727272;
    }
  }
  .button-send {
    text-align: right;
    display: inline-block;
    margin-bottom: 0rem !important;
    button {
      border-radius: 5px;
      background-color: #003399;
      border: none;
      color: white;
      padding: 0.5rem 3rem;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #f8f9fa;
    }
  }
}

.pt-1 {
  padding-top: 1rem;
}

@media screen and (max-width: 768px) {
  .form-contact {
    border: none;
    padding: 0.25rem 1rem;
  }
}
