.card-vertical {
  padding-left: 15px;
  padding-right: 15px;
  .item {
    margin-top: 1rem;
    cursor: pointer;
    .content-item {
      margin-right: auto;
      .div-img {
        padding: 0;
        img {
          max-width: 100%;
          object-fit: cover;
          min-height: 200px;
          height: 200px;
          width: 100%;
        }
        label {
          position: absolute;
          left: 0;
          top: 0;
          background-color: black;
          color: white;
          font-size: 11px;
          padding: 0.25rem 1.5rem;
          margin-left: 0.5rem;
          margin-top: 0.5rem;
          font-weight: 500;
        }
      }
      .div-details {
        background-color: white;
        padding: 1rem;
        h6 {
          font-size: 18px;
          font-weight: bold;
          color: #393939;
          font-size: 16px;
        }
        .description {
          font-size: 14px;
          line-height: normal;
          color: #4a4a4a;
          margin-top: 1rem;
          width: 100%;
          cursor: pointer;
          padding-bottom: 2rem;
        }
        .price {
          font-size: 16px;
          font-weight: 500;
          color: #4a4a4a;
          position: absolute;
          bottom: 0;
          right: 1rem;
          cursor: pointer;
        }
      }
      .footer {
        background: #f6f6f6;
        padding: 0.5rem;
        min-height: 54px;
        .features {
          text-align: center;
          padding: 0;
          line-height: 1rem;
          label {
            font-size: 12px;
            font-weight: 500;
            color: #4a4a4a;
            margin-bottom: 0;
            width: 100%;
          }
          img {
            width: 20px;
          }
        }
      }
    }
  }
}

// media for mobile
@media (max-width: 768px) {
  .card-vertical {
    width: 100%;
  }
}
